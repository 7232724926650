// @flow
import React from 'react';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';

import Layout from '../../components/Layout/Layout';
import Hero from '../../components/Merchant/shared/Hero';
import Bg from '../../images/application.svg';
import BgSp from '../../images/application-sp.svg';
import ApplySection from '../../components/Merchant/ApplySection';
import { SITE_METADATA } from '../../constants';

const Navigation = loadable(() =>
  import(
    /* webpackPreload: true */ '../../components/Merchant/Application/Navigation'
  )
);

const ApplicationPage = ({ location }: { location: Object }) => {
  return (
    <Layout
      isMerchant
      SEOProps={{
        title: SITE_METADATA.merchantApplication.title,
        description: SITE_METADATA.merchantApplication.description,
      }}
    >
      <LazyLoad height={216} offset={100} once>
        <Hero title="導入の流れ" bg={Bg} bgSp={BgSp} />
      </LazyLoad>
      <LazyLoad height={500} offset={400} once>
        <Navigation location={location} />
      </LazyLoad>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default ApplicationPage;
